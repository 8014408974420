/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isTokenValid, } from '../api/common';
import { SITE_BASE_PATH } from '../constant';

const PrivateRoute = ({ component: Component, section: Section, mapping: Mapping, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      isTokenValid()
      ? <Component {...props} />
      : <Redirect to={`${SITE_BASE_PATH}login`} />
    )}
  />
);

export default PrivateRoute;
