/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

/**
 * If user accessing login or signup pages. 
 * But user is already logged in then we will redirect the user to Home page.
 */
import { Route, Redirect } from 'react-router-dom';
import { SITE_BASE_PATH } from '../constant';
import { isTokenValid, } from '../api/common';

const AuthenticationRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      !isTokenValid()
        ? <Component {...props} />
        : <Redirect to={`${SITE_BASE_PATH}`} />
    )}
  />
);

export default AuthenticationRoute;
