import { combineReducers } from '@reduxjs/toolkit';
import headerSliceReducer from './headerSlice';
import appDataSlice from './appDataSlice';



const rootReducer = combineReducers({
  header: headerSliceReducer,
  appData: appDataSlice,
});

export default rootReducer;
